import 'angular-gettext';
import * as Upload from 'ng-file-upload';
import { get, keys } from 'lodash/fp';
import { HelpService } from '../../../common/help/help.service';
import { StateService } from '@uirouter/core';
import alerts, { AlertsService } from '../../../common/alerts/alerts.service';
import api, { ApiService } from '../../../common/api/api.service';
import uiRouter from '@uirouter/angularjs';
import users, { UsersService } from '../../../common/users/users.service';

export class PreferencesOrganizationService {
    data: any;
    state: string;
    values_to_constants_mappings: any;
    blockUI: IBlockUIService;
    constructor(
        private $log: ng.ILogService,
        private $window: ng.IWindowService,
        private Upload: ng.angularFileUpload.IUploadService,
        private alerts: AlertsService,
        private api: ApiService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private users: UsersService,
        private help: HelpService,
        private $state: StateService,
        blockUI: IBlockUIService,
    ) {
        this.data = {};
        this.state = 'disabled';
        this.blockUI = blockUI.instances.get('preferences-integrations-organization');
    }
    save(successMessage, errorMessage) {
        return this.api.put('user/organization_accounts', this.data, successMessage, errorMessage).then((data) => {
            this.data.organization_accounts = data;
            return this.updateState();
        });
    }
    sync(id, successMessage, errorMessage): ng.IPromise<void> {
        return this.api.get({
            url: `user/organization_accounts/${id}/sync`,
            successMessage: successMessage,
            errorMessage: errorMessage,
        });
    }
    disconnect(id, successMessage, errorMessage) {
        return this.api.delete({
            url: `user/organization_accounts/${id}`,
            type: 'organization_accounts',
            successMessage: successMessage,
            errorMessage: errorMessage,
        });
    }
    updateState() {
        if (this.data.active) {
            if (this.data.valid) {
                this.state = 'enabled';
            } else {
                this.state = 'error';
            }
        } else {
            this.state = 'disabled';
        }
    }
    createAccount(username, password, organizationId, importOption, workerId) {
        const successMessage = this.gettextCatalog.getString('Partner Essentials added your organization account');
        const errorMessage = this.gettextCatalog.getString('Invalid username or password.');
        let org: any = {
            import_option: importOption,
            organization: {
                id: organizationId,
            },
            person: {
                id: this.users.current.id,
            },
            worker_identifier: workerId,
        };
        if (username && username.length > 0) {
            org.username = username;
        }
        if (password && password.length > 0) {
            org.password = password;
        }
        return this.api
            .post({
                url: 'user/organization_accounts',
                data: org,
                overridePromise: true,
            })
            .then(() => {
                this.alerts.addAlert(successMessage);
            })
            .catch((ex) => {
                this.alerts.addAlert(errorMessage, 'danger');
                throw ex;
            });
    }
    updateAccount(username, password, accountId, successMessage, errorMessage) {
        return this.api.put({
            url: `user/organization_accounts/${accountId}`,
            data: {
                id: accountId,
                username: username,
                password: password,
            },
            type: 'organization_accounts',
            successMessage: successMessage,
            errorMessage: errorMessage,
        });
    }
    import(account) {
        return this.Upload.upload({
            method: 'POST',
            url: `${process.env.API_URL}/account_lists/${this.api.account_list_id}/imports/tnt_data_sync`,
            data: {
                data: {
                    type: 'imports',
                    attributes: {
                        file: account.file,
                    },
                    relationships: {
                        source_account: {
                            data: {
                                id: account.id,
                                type: 'organization_accounts',
                            },
                        },
                    },
                },
            },
        });
    }
    uploadDonation(account) {
        this.blockUI.start();
        return this.Upload.upload({
            method: 'POST',
            url: `${this.api.apiUrl}/account_lists/${this.api.account_list_id}/imports/donation`,
            data: {
                data: {
                    type: 'imports',
                    attributes: {
                        file: account.file,
                    },
                    relationships: {
                        source_account: {
                            data: {
                                id: account.id,
                                type: 'organization_accounts',
                            },
                        },
                    },
                },
            },
        })
            .then((resp: any) => {
                this.blockUI.reset();
                if (resp.data.data.id) {
                    this.next(resp.data.data.id);
                }
            })
            .catch((error) => {
                this.blockUI.reset();
                /* istanbul ignore next */
                this.$log.error(error);
                this.alerts.addAlert(
                    this.gettextCatalog.getString(
                        'Invalid CSV file - See help docs or send us a message with your CSV attached',
                    ),
                    'danger',
                    error.status,
                );
                if (this.help.variables().HS_TOOLS_IMPORT_CVS_ERROR?.length) {
                    this.help.article(this.gettextCatalog.getString(this.help.variables().HS_TOOLS_IMPORT_CVS_ERROR));
                }
                throw error;
            });
    }
    next(importId: string): void {
        const stateSwitch = (state) =>
            ({
                'preferences.integrations': 'preferences.donation.headers',
            }[state]);
        const nextState = stateSwitch(this.$state.$current.name);
        if (nextState) {
            this.$state.go(nextState, { importId: importId });
        } else {
            this.$state.go('preferences.integrations');
        }
    }
    oAuth(
        organizationId,
        route = 'preferences/integrations?selectedTab=organization',
        organizationType = 'DataServer',
    ) {
        const type =
            organizationType === 'DataServerNeonCrm'
                ? 'neoncrm'
                : organizationType === 'DataServerTouchPoint'
                ? 'touchpoint'
                : organizationType === 'DataServerSalesforce'
                ? 'salesforce'
                : 'donorhub';
        const redirectUrl = this.$window.encodeURIComponent(`${this.$window.location.origin}/${route}`);
        const token = this.$window.localStorage.getItem('token');
        const accountListId = this.api.account_list_id;
        return (
            `${process.env.OAUTH_URL}/${type}?account_list_id=${accountListId}` +
            `&redirect_to=${redirectUrl}` +
            `&access_token=${token}` +
            `&organization_id=${organizationId}` +
            `&auth_url=${process.env.OAUTH_URL}`
        );
    }
    ministryAccountRequired(selected): boolean {
        const apiClasses = ['Siebel', 'Remote::Import::OrganizationAccountService'];
        return get('api_class', selected) && apiClasses.indexOf(selected.api_class) !== -1;
    }
    loginRequired(selected): boolean {
        const apiClasses = [
            'DataServer',
            'DataServerPtc',
            'DataServerNavigators',
            'DataServerStumo',
            'DataServerVirtuous',
            'DataServerTouchPoint',
            'DataServerSalesforce',
        ];
        return (
            !this.oauthRequired(selected) && get('api_class', selected) && apiClasses.indexOf(selected.api_class) !== -1
        );
    }
    oauthRequired(selected): boolean {
        return get('oauth', selected);
    }
    exportDonors(id, successMessage, errorMessage): ng.IPromise<void> {
        return this.api.get({
            url: `user/organization_accounts/${id}/export_donors`,
            successMessage: successMessage,
            errorMessage: errorMessage,
        });
    }
}

export default angular
    .module('mpdx.preferences.integrations.organization.service', [
        'blockUI',
        uiRouter,
        Upload,
        'gettext',
        alerts,
        api,
        users,
    ])
    .service('preferencesOrganization', PreferencesOrganizationService).name;
