import 'angular-gettext';
import { get } from 'lodash/fp';
import accounts, { AccountsService } from '../../common/accounts/accounts.service';
import api, { ApiService } from '../../common/api/api.service';
import help, { HelpService } from '../../common/help/help.service';
import preferencesOrganization, {
    PreferencesOrganizationService,
} from '../../preferences/integrations/organization/organization.service';
import serverConstants, { ServerConstantsService } from '../../common/serverConstants/serverConstants.service';
import setup, { SetupService } from '../setup.service';
import users, { UsersService } from '../../common/users/users.service';

export class ConnectController {
    addOrganization: boolean;
    organization: any;
    password: string;
    importOption: string;
    workerId: string;
    saving: boolean;
    selected: any;
    selectedKey: string;
    username: string;
    showHelpButton = true;
    defaultOrgName = 'Partner Essentials DIY';
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $window: ng.IWindowService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService,
        private api: ApiService,
        private help: HelpService,
        private preferencesOrganization: PreferencesOrganizationService,
        private serverConstants: ServerConstantsService,
        private users: UsersService,
        private setup: SetupService,
    ) {
        if (!this.help.variables().HS_SETUP_FIND_ORGANIZATION?.length) {
            this.showHelpButton = false;
        }
    }
    $onInit(): void {
        this.reset();
        this.users.listOrganizationAccounts().then(() => {
            this.addOrganization = this.users.organizationAccounts.length === 0;
        });
        this.$rootScope.$on('accountListUpdated', () => {
            this.users.listOrganizationAccounts(true);
        });
        this.getDefaultOrgKey();
    }
    add(): ng.IPromise<void> {
        this.saving = true;
        return this.preferencesOrganization
            .createAccount(this.username, this.password, this.selectedKey, this.importOption, this.workerId)
            .then(() => {
                return this.users.listOrganizationAccounts(true).then(() => {
                    this.saving = false;
                    this.addOrganization = false;
                    this.username = '';
                    this.password = '';
                });
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
    disconnect(id): ng.IPromise<void> {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString(
            'Partner Essentials removed your organization integration',
        );
        const errorMessage = this.gettextCatalog.getString(
            "Partner Essentials couldn't save your configuration changes for that organization",
        );
        return this.preferencesOrganization
            .disconnect(id, successMessage, errorMessage)
            .then(() => {
                this.saving = false;
                return this.users.listOrganizationAccounts(true).then(() => {
                    this.addOrganization = this.users.organizationAccounts.length === 0;
                });
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
    reset(): void {
        this.addOrganization = false;
        this.saving = false;
        this.organization = null;
        this.username = '';
        this.password = '';
        this.workerId = null;
    }
    select(): void {
        this.username = '';
        this.password = '';
        this.selected = get(this.selectedKey, this.serverConstants.data.organizations_attributes);
    }
    next(): ng.IPromise<void> {
        this.saving = true;
        return this.setup.next().then(() => {
            this.saving = false;
        });
    }
    showOrganizationHelp(): void {
        this.help.article(this.gettextCatalog.getString(this.help.variables().HS_SETUP_FIND_ORGANIZATION));
    }
    authenticate(organizationId): void {
        this.saving = true;
        this.$window.location.href = this.preferencesOrganization.oAuth(
            organizationId,
            'setup/connect',
            this.selected.api_class,
        );
    }
    getDefaultOrgKey(): void {
        for (const key in this.serverConstants.data.organizations_attributes) {
            if (this.serverConstants.data.organizations_attributes[key].name === this.defaultOrgName) {
                this.selectedKey = key;
                break;
            }
        }
        this.select();
    }
}

const Connect = {
    template: require('./connect.html'),
    controller: ConnectController,
};

export default angular
    .module('mpdx.setup.connect.component', [
        'gettext',
        accounts,
        api,
        help,
        preferencesOrganization,
        serverConstants,
        setup,
        users,
    ])
    .component('setupConnect', Connect).name;
